import React, { useState } from "react"
import { graphql } from "gatsby"
import { Icon, Menu, Item } from "semantic-ui-react"
import SEO from "../../../components/seo"
import "../../general.css"
import {
    chartTitleTextStyle,
    vizBackgroundStyle
} from "../../../defines";

const Title = "Documentaries";

export const query = graphql`
    query {
        allDocumentariesJson {
            nodes {
              synopsis
              tags
              title
              trailer
              watch
            }
          }
    }
`;

const getDocumentaryData = (nodes) => {
    let result = {};
    nodes.forEach(n => {
        n.tags.forEach(tag => {
            if (!result[tag]) { result[tag] = []; }
            result[tag].push(n);
        });
    });
    return result;
};

const CategoryToIcon = {
    Animals: "paw",
    Environment: "globe",
    Health: "heartbeat",
    Other: "ellipsis horizontal"
}

const PredefinedSites = {
    "youtube.com": "YouTube",
    "vimeo.com": "Vimeo",
    "amazon.com": "Amazon Prime Video",
    "netflix.com": "Netflix",
    "disneyplus.com": " Disney+"
};

const Documentaries = ({ data, mobile }) => {
    const documentaryData = getDocumentaryData(data.allDocumentariesJson.nodes);

    const [selectedCategory, setSelectedCategory] = useState("Animals");
    const onClick = (e, evt) => { setSelectedCategory(evt.name); };

    const toProvider = (link) => {
        let result;
        Object.keys(PredefinedSites).forEach(key => {
            if (link.indexOf(key) !== -1) {
                result = PredefinedSites[key];
            }
        });
        if (!result) { return link; }
        return result;
    }

    const toWatch = (link) => {
        let result = "Watch at";
        Object.keys(PredefinedSites).forEach(key => {
            if (link.indexOf(key) !== -1) {
                result = "Watch on";
            }
        });
        return result;
    }

    return (
        <>
            <SEO title={Title} />
            <div className="contentDiv">
                <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
                <div className="vizBackground" style={vizBackgroundStyle(mobile)}>
                    <div style={{ textAlign: "center", marginBottom: 40 }}>
                        <Menu compact icon="labeled" size={mobile ? "tiny" : null}>
                            {
                                Object.keys(documentaryData).map(k => `${k[0].toUpperCase()}${k.substring(1)}`).map(k => (
                                    <Menu.Item
                                        name={k}
                                        key={k}
                                        active={k === selectedCategory}
                                        color={"green"}
                                        onClick={onClick}
                                    >
                                        <Icon name={CategoryToIcon[k]}/>
                                        {k}
                                    </Menu.Item>
                                ))}
                        </Menu>
                    </div>
                    <div>
                        <Item.Group divided>
                            {
                                documentaryData[selectedCategory.toLowerCase()].map(details => (
                                    <Item key={details.title}>
                                        <Item.Image size="small" src={`/documentaries/${details.title.toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, "")}.png`} />
                                        <Item.Content>
                                            <Item.Header>{details.title}</Item.Header>
                                            <Item.Meta>
                                                <span>{toWatch(details.watch)}</span>
                                                <span>{<a href={details.watch}>{toProvider(details.watch)}</a>}
                                                </span>
                                            </Item.Meta>
                                            <Item.Description>
                                                {details.synopsis}
                                                <div style={{ textAlign: "center", marginTop: 10 }}>
                                                    {details.trailer ?
                                                        mobile ? <a href={details.trailer}>See trailer</a> : <iframe title={details.title} width="480" height="360" src={details.trailer} frameBorder="0" allow="autoplay; fullscreen" />
                                                        : (<></>)
                                                    }
                                                </div>
                                            </Item.Description>
                                        </Item.Content>
                                    </Item>
                                ))
                            }
                        </Item.Group>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Documentaries;
